.home{
  background-color: #F9FAFB; 
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}

.nav{
  width: 30vw;
  margin: 0 auto;
  margin-top: 2vh;
  font-size: 2.1vh;
  display: flex;
  justify-content: space-around;
}

.profilePic{
  height: 20vh;
  filter: grayscale(25%);
  margin-left: 7vw;
}

.about{
  margin: 0 auto;
  margin-top: 7vh;
  width: 45vw;
  /* background-color: antiquewhite; */
  display: flex;
}

.contacts{
  margin-top: 10px;
  /* background-color: #00ddeb; */
  display: flex;
  /* width: 40%; */
}

.name_about{
  font-family: "Montserrat", sans-serif;
  font-size: 5vh;
  font-weight: 900;
}

.role_about{
  margin-top: 1vh;
  font-family: "Montserrat", sans-serif;
  font-size: 2vh;
  font-weight: 200;
}

.obj_about{
  margin-top: 1.5vh;
  font-family: "Montserrat", sans-serif;
  font-size: 2.2vh;
  font-weight:500
}

.featured{
  /* background-color: antiquewhite; */
  margin: 0 auto;
  margin-top: 7vh;
  width: 45vw;
}

.title_featured{
  font-family: "Montserrat", sans-serif;
  font-size: 3vh;
  font-weight: 600;
}

.featureListPallet{
  display: flex;
  justify-content: space-between;
  margin-top: 3vh;
}

.experience{
  /* background-color: antiquewhite; */
  margin: 0 auto;
  margin-top: 7vh;
  width: 45vw;
}

.title_experience{
  font-family: "Montserrat", sans-serif;
  font-size: 3vh;
  font-weight: 600;
}

.experience_container{
  /* background-color: #00ddeb; */
  margin-top: 3vh;
}

.indiv_experience{
  width: fit-content;
  padding: 10px;
}

.companyLogo{
  width: fit-content;
  margin: 0 auto;
}

.company{
  font-size: 3vh;
  font-weight: 500;
  width: fit-content;
  margin: 0 auto;
}

.company_role_duration{
  font-style: italic;
  margin: 0 auto;
  padding: 10px;
  font-size: 2vh;
  width: fit-content;
}

.company_summary{
  text-align: justify;
  width: 80%;
  margin: 0 auto;
  background-color: #F9FAFB; 
  padding: 10px;
  border: 1px solid #aca9a9; 
  border-radius: 10px;
}

.education{
  margin: 0 auto;
  margin-top: 7vh;
  width: 45vw;
}

.title_education{
  font-family: "Montserrat", sans-serif;
  font-size: 3vh;
  font-weight: 600;
  margin-bottom: 5vh;
}

.indiv_education{
  padding: 1vw;
  padding: 10px;
  border: 1px solid #aca9a9; 
  border-radius: 10px;
}

.indiv_education_right{
  font-size: 2vh;
  font-weight: 700;
  width: fit-content;
  margin: 0 auto;
}

.indiv_education_right_span{
  font-size: 1.8vh;
  font-weight: 500;
  width: fit-content;
  margin: 0 auto;
}

.education_list{
  display: flex;
  justify-content: space-around;
}

.schoolLogo{
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 2vh;
}

.projects{
  margin: 0 auto;
  margin-top: 7vh;
  width: 60vw;
}

.title_projects{
  font-family: "Montserrat", sans-serif;
  font-size: 3vh;
  font-weight: 600;
  width: 45vw;
  margin: 0 auto;
  margin-bottom: 5vh;
}

.indiv_project{
  display: flex;
  margin-bottom: 4vh;
}

.project_data{
  /* background-color: #00ddeb; */
  margin-left: 2vw;
}

.project_date{
  font-size: 2vh;
}

.project_data_title{
  font-size: 3vh;
  font-weight: 500;
}

.button_grp{
  display: flex;
  margin-top: 2vh;
  /* width: 15vw; */
  /* background-color: #00ddeb; */
  /* justify-content: space-around; */
}

.demo_btn{
  background-color: #4a78ba;
  color: #f6f7f8;
  font-weight: 700;
  border-color: #38383800;
  margin-left: 1vw;
}

.code_btn{
  background-color: #383838;
  color: #f6f7f8;
  font-weight: 700;
  border-color: #383838;
  margin-left: 1vw;
}

.projects_skills{
  font-size: 1.6vh;
  font-weight: 500;
  color: #383838;
  margin-top: 1vh;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  margin-left: 2vw;
  /* background-color: #054448; */
}

.project_skill{
  margin-top: 1.5vh;
  margin-left: 5px;
  background-color: #42f360;
  padding: 2px 5px;
  border-radius: 5px;
  height: fit-content;
}

/* ______ */


.e-card {
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
  position: relative;
  width: 14vw;
  height: 40vh;
  border-radius: 16px;
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
  background: linear-gradient(744deg,#af40ff,#5b42f3 60%,#00ddeb);
}

.icon {
  width: 3em;
  margin-top: -1em;
  padding-bottom: 1em;
}



.featured_title {
  font-size: 3.5vh;
  font-weight: 700;
  position: relative;
  top: 1vh;
  padding: 10px;
  color: #f6f7f8;
}
.featured_date{
  font-size: 2vh;
  font-weight: 500;
  position: relative;
  top: 1vh;
  padding: 10px;
  color: #e9e9e9;
}

.featured_skills{
  font-size: 1.5vh;
  font-weight: 500;
  position: relative;
  top: 1vh;
  color: #383838;
  margin-top: 1vh;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
}

.featured_skill{
  margin-top: 5px;
  margin-left: 5px;
  background-color: #42f360;
  padding: 2px 5px;
  border-radius: 5px;
}

.featured_final{
  font-size: 2vh;
  font-weight: 500;
  position: relative;
  top: 1vh;
  margin-top: 2vh;
  padding: 10px;
  color: #e9e9e9;
}

.wave:nth-child(2),
.wave:nth-child(3) {
  top: 210px;
}

.playing .wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.playing .wave:nth-child(2) {
  animation-duration: 4000ms;
}

.wave:nth-child(2) {
  animation-duration: 50s;
}

.playing .wave:nth-child(3) {
  animation-duration: 5000ms;
}

.wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* _______________ */
button {
  position: relative;
  margin: 0;
  padding: 17px 35px;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #fff;
  border: 1px solid rgba(22, 76, 167, 0.6);
  border-radius: 10px;
  color: #1d89ff;
  font-weight: 400;
  font-family: inherit;
  z-index: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

button span {
  color: #164ca7;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
}

button:hover {
  animation: rotate624 0.7s ease-in-out both;
}

button:hover span {
  animation: storm1261 0.7s ease-in-out both;
  animation-delay: 0.06s;
}

@keyframes rotate624 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes storm1261 {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }

  25% {
    transform: translate3d(4px, 0, 0) translateZ(0);
  }

  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0);
  }

  75% {
    transform: translate3d(2px, 0, 0) translateZ(0);
  }

  100% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
}

.btn-shine {
  border: 1px solid;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  margin-top: 1vh;
}

.btn-shine span {
  z-index: 20;
}

.btn-shine:after {
  background: #38ef7d;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.4;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: -10;
}

.btn-shine:hover:after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

/* __________________ */

